import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
class SiteContentEsdFlooring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          id: 1,
          text01:
            "The most popular form of epoxy resin that is used for epoxy coatings is Bisphenol A. Bisphenol A has a honey-liquid consistency and is an extremely versatile resin. Bisphenol A is available in many molecular weights. Their variety of molecular weights is what makes Bisphenol A especially versatile for the epoxy application. Bisphenol A is most widely used for flooring systems, in addition to solid coatings.  Bisphenol A epoxy resin is the most common type for concrete coatings because of its excellent adhesion, toughness, abrasion resistance, and chemical resistance. Due to its especially thick natural consistency, there will commonly be additives and diluents which are added into the Bisphenol-A epoxy formula to enhance workability and adhesion. These additives lessen crosslink density which lowers the chemical resistance.",
          image: "assets/img/ServicePage/Bisphenol-A.jpg",
        },
        {
          id: 2,
          text01:
            "Bisphenol F epoxy will typically be more expensive than Bisphenol A epoxy. This is because Bisphenol F epoxy is able to be used for a greater variety of applications. Bisphenol F resins have lower viscosity which means fewer additives and diluents are added which translates to higher chemical resistance. Bisphenol F is especially chemically resistant, and are more likely to be used as varnishes or lacquers, and for application regarding water pipes, adhesives, supermarket food packaging, plastics, dental sealants, and liners. Another advantage is that Bisphenol F has less tendency to crystallize at lower temperatures compared to Bisphenol A resins. Bisphenol F can be blended with Bisphenol A and other resins to reduce viscosity and increase resistance to crystallization.",
          image: "assets/img/ServicePage/Bisphenol-F.jpg",
        },
        {
          id: 3,
          text01:
            "Novolacs are made using excess phenol and modifying Bisphenol F Resins. The viscosity of Novolac resins is higher than Bisphenol F and its functionality is considerably greater than both Bisphenol A and Bisphenol F resins. The higher viscosity and greater functionality of Novolacs give them superior heat and chemical resistance than their Bisphenol A and Bisphenol F counterparts. The greater chemical resistant qualities is a result of the very tight crosslink density because of Novolac’s higher functionality. These properties also make Novolac resins more brittle than Both Bisphenol A and F resins so formulators need to address that with additives and hardener selection. The greater heat resistance is the result of the larger number of aromatic rings. As to be expected Novolac resins have a higher cost to go along with their higher performance.",
          image: "assets/img/ServicePage/Novolac.jpg",
        },
      ],
    };
  }

  render() {
    return (
      <div id="site-content" className="site-content clearfix">
        <Helmet>
          <title>ESD Flooring | Electrostatic Discharge Flooring</title>
          <meta
            name="description"
            content="Electrostatic Discharge Flooring (ESD) prevents static buildup and protects sensitive electronics in environments like data centers, labs, and manufacturing. Durable, safe, and easy to maintain, ESD flooring ensures a static-free workspace."
          />
          <meta
            name="keywords"
            content="ESD Flooring, Electrostatic Discharge, Durable Flooring, Chem Coats"
          />
          <meta name="author" content="Chem Coats" />
          <link rel="canonical" href="https://chemcoats.com/esd-flooring" />
          <meta name="robots" content="index, follow" />
        </Helmet>
        <div id="inner-content" className="inner-content-wrap">
          <div
            className="themesflat-spacer clearfix"
            data-desktop={80}
            data-mobile={60}
            data-smobile={60}
          />
          <div className="themesflat-row equalize sm-equalize-auto clearfix">
            <div className="span_1_of_6 bg-f7f">
              <div
                className="themesflat-spacer clearfix"
                data-desktop={60}
                data-mobile={60}
                data-smobile={60}
              />
              <div
                className="themesflat-content-box clearfix"
                data-margin="0 10px 0 43px"
                data-mobilemargin="0 15px 0 15px"
              >
                <div className="themesflat-headings style-2 clearfix">
                  {/* <div className="sup-heading">SPECIAL SERVICES</div> */}
                  <h1 className="heading font-size-28 line-height-39">
                    ESD FLOORING
                  </h1>
                  <div className="sep has-width w80 accent-bg margin-top-20 clearfix" />
                  <p className="sub-heading margin-top-33 line-height-24">
                    {/* ESD (Electrostatic Discharge) flooring is engineered with
                    conductive materials to provide a controlled path for the
                    dissipation of electrostatic charges, effectively preventing
                    static electricity buildup and protecting sensitive
                    electronic components in environments where electrostatic
                    discharge poses a risk. */}
                    ESD, which stands for Electrostatic Discharge, floors are
                    designed of conductive material, which directs electrostatic
                    charge away from sensitive electrical components in cases
                    where electrostatic discharge is expected to be a factor in
                    the protection of sensitive components.
                  </p>
                </div>
              </div>
              <div
                className="themesflat-spacer clearfix"
                data-desktop={56}
                data-mobile={56}
                data-smobile={56}
              />
            </div>
            <div className="span_1_of_6 half-background style-2">
              <img
                src="assets/img/ServicePage/esd-flooring-5.jpg"
                alt="ESD-flooring-images"
                title="ESD-flooring-images"
                className="ServiceIDpAGEiMAGE"
              />
            </div>
          </div>
          <div
            className="themesflat-spacer clearfix"
            data-desktop={39}
            data-mobile={39}
            data-smobile={39}
          />
          <div className="flat-content-wrap style-2 clearfix">
            {/* <h5 className="title">SERVICE CONSULTANT</h5> */}
            <p>
              {/* ESD (Electrostatic Discharge) flooring plays a pivotal role in
              safeguarding sensitive electronic equipment and components. Its
              paramount importance lies in its ability to dissipate
              electrostatic charges, preventing potentially damaging static
              electricity discharges that can compromise the functionality and
              lifespan of electronics. By providing a controlled path for static
              discharge, ESD flooring ensures a secure environment for
              manufacturing, assembly, and testing processes, reducing the risk
              of costly electrostatic damage. */}
              ESD flooring is highly crucial in shielding sensitive electronic
              equipment and its components. It is most important for its ability
              to dissipate electrostatic charges, which prevents damage by
              possible static electricity discharges that can compromise the
              functionality and lifespan of electronic devices. ESD flooring
              will ensure a safe environment for manufacturing, assembly, and
              testing processes by providing a controlled path for static
              discharge.
            </p>
          </div>
          <div
            className="themesflat-spacer clearfix"
            data-desktop={37}
            data-mobile={35}
            data-smobile={35}
          />
          <div className="flat-content-wrap style-2 clearfix">
            <div className="item" style={{ width: "100%" }}>
              <div className="inner">
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={10}
                  data-mobile={10}
                  data-smobile={10}
                />
                <h5 className="title">Importance of ESD Flooring ?</h5>
                <p>
                  {/* ESD (Electrostatic Discharge) flooring serves as a critical
                  component in industries where static electricity poses a
                  significant threat. Here are ten key reasons for its
                  importance, along with explanations: */}
                  The application of ESD flooring plays a significant role in
                  sectors where the effect of static electricity is more
                  dominant. There are ten essential reasons along with their
                  elaboration as under:
                </p>
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={8}
                  data-mobile={8}
                  data-smobile={8}
                />
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          Electrostatic Discharge Control :
                        </span>{" "}
                        {/* ESD flooring effectively controls and dissipates static
                        charges, preventing sudden discharges that can damage
                        sensitive electronic components. */}
                        This flooring effectively controls and dissipates the
                        static charge. Sudden discharge is therefore averted
                        that damages the sensitive electronic components.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          Protection of Electronics :
                        </span>{" "}
                        {/* It safeguards electronic equipment, such as microchips
                        and circuit boards, from potentially costly and
                        disruptive ESD events. */}
                        This protects electronic components like microchips and
                        circuit boards from possible costly and disruptive ESD
                        events.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          Worker Safety :
                        </span>{" "}
                        {/* ESD flooring ensures the safety of personnel working
                        with electronic devices by reducing the risk of electric
                        shocks. */}
                        ESD flooring keeps employees handling electronic
                        equipment safe from the threat of electrical shock.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          Prevention of Data Loss :
                        </span>{" "}
                        {/* ESD events can lead to data loss or corruption; ESD
                        flooring minimizes this risk, especially in data centers
                        and server rooms. */}
                        Data loss or corruption can result from an ESD event;
                        ESD flooring prevents this possibility, especially in
                        data centers and server rooms.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          Fire Hazard Reduction :
                        </span>{" "}
                        {/* Static electricity can ignite flammable materials in
                        certain environments; ESD flooring mitigates this fire
                        hazard. */}
                        Static electricity can light up flammable materials in
                        particular environments; ESD Flooring reduces this type
                        of fire hazard.
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      <span style={{ fontWeight: "500", color: "#000" }}>
                        Compliance with Industry Standards :
                      </span>{" "}
                      {/* It meets industry-specific ESD control standards and
                      regulations, ensuring compliance in critical sectors like
                      aerospace and healthcare. */}
                      It meets industry specific ESD Control standards and
                      requirements, thereby supporting compliance in important
                      industries such as aerospace and health care.
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      <span style={{ fontWeight: "500", color: "#000" }}>
                        Enhanced Productivity :
                      </span>{" "}
                      {/* By minimizing the disruption caused by ESD events, ESD
                      flooring helps maintain uninterrupted manufacturing and
                      testing processes. */}
                      Because it reduces disruptions caused by an ESD event, ESD
                      flooring supports continuous flow of manufacturing and
                      testing processes.
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      <span style={{ fontWeight: "500", color: "#000" }}>
                        Long-Term Cost Savings :
                      </span>{" "}
                      {/* Preventing ESD-related damage extends the lifespan of
                      electronic components and reduces replacement and repair
                      costs. */}
                      Preventing ESD-related damage extends the lifespan of
                      electronic components and reduces replacement and repair
                      costs.
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      <span style={{ fontWeight: "500", color: "#000" }}>
                        Cleanroom Suitability :
                      </span>{" "}
                      {/* ESD flooring is integral to cleanroom environments, where
                      maintaining a contamination-free atmosphere is essential
                      for product quality. */}
                      ESD-type flooring includes ESD flooring in this case, and
                      would be specifically designed where it admits cleanroom
                      environments and whichever would be a clean air area to
                      ensure that no contamination affects the product.
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      <span style={{ fontWeight: "500", color: "#000" }}>
                        Customization Options :
                      </span>{" "}
                      {/* It offers a range of customization options, including
                      different materials and designs, to meet the specific
                      needs and aesthetics of different industries. */}
                      It perfectly offers a multiplicity of customization
                      options with different designs and materials, all designed
                      to meet the needs and aesthetics of the various
                      industries.
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      {/* In conclusion, ESD flooring is indispensable in sectors
                      where static electricity can wreak havoc on sensitive
                      electronics, worker safety, and data integrity. Its
                      ability to control ESD events and meet industry standards
                      ensures a secure and efficient operational environment,
                      ultimately leading to cost savings and improved
                      productivity. */}
                      In conclusion, ESD flooring is indeed a necessity to keep
                      the industrial process safe and free from disruption: a
                      platform that limits all the possible discharge
                      occurrences, complies with the norms being prescribed by
                      the industry, creating a safe and functioning environment
                      conducive to saving on costs yet improving productivity.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Image Slider */}
          <div
            className="themesflat-spacer clearfix"
            data-desktop={37}
            data-mobile={35}
            data-smobile={35}
          />
          <div className="row-services">
            <div className="container">
              <h5 className="title">ESD Flooring Images :</h5>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="themesflat-carousel-box data-effect clearfix"
                    data-gap={30}
                    data-column={3}
                    data-column2={2}
                    data-column3={1}
                    data-auto="true"
                  >
                    <div className="owl-carousel owl-theme">
                      <div className="themesflat-image-box style-2 clearfix">
                        <div className="image-box-item">
                          <div className="inner">
                            <div className="thumb data-effect-item">
                              <img
                                src="assets\img\ServicePage\epoxy-flooring3.jpg"
                                alt="ESD-flooring-images"
                                title="ESD-flooring-images"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="themesflat-image-box style-2 clearfix">
                        <div className="image-box-item">
                          <div className="inner">
                            <div className="thumb data-effect-item">
                              <img
                                src="assets\img\ServicePage\PU1.jpg"
                                alt="ESD-flooring-images"
                                title="ESD-flooring-images"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="themesflat-image-box style-2 clearfix">
                        <div className="image-box-item">
                          <div className="inner">
                            <div className="thumb data-effect-item">
                              <img
                                src="assets\img\ServicePage\Di-electric-flooring.jpg"
                                alt="ESD-flooring-images"
                                title="ESD-flooring-images"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="themesflat-image-box style-2 clearfix">
                        <div className="image-box-item">
                          <div className="inner">
                            <div className="thumb data-effect-item">
                              <img
                                src="assets\img\ServicePage\IMG-20220628-WA0007.jpg"
                                alt="ESD-flooring-images"
                                title="ESD-flooring-images"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="themesflat-image-box style-2 clearfix">
                        <div className="image-box-item">
                          <div className="inner">
                            <div className="thumb data-effect-item">
                              <img
                                src="assets\img\ServicePage\esd-flooring-5.jpg"
                                alt="ESD-flooring-images"
                                title="ESD-flooring-images"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="themesflat-spacer clearfix"
                      data-desktop={45}
                      data-mobile={60}
                      data-smobile={60}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Image Slider */}
          <div className="flat-content-wrap style-2 clearfix">
            <div className="item" style={{ width: "100%" }}>
              <div className="inner">
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={10}
                  data-mobile={10}
                  data-smobile={10}
                />
                <h5 className="title">
                  Why Choose ChemCoats For ESD Flooring?
                </h5>

                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={8}
                  data-mobile={8}
                  data-smobile={8}
                />
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        With 15 years of experience in providing Industrial
                        Dielectric flooring services in India,
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        We offer expert guidance and meticulous attention to
                        every detail,
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        Ensuring 100% transparency and customer satisfaction is
                        our top priority.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        Our commitment to exceptional craftsmanship results in a
                        dependable flooring system designed for long-lasting
                        performance.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        We employ high-quality installation services utilizing
                        modern equipment,
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      At Chemcoats, our core vision is to meet all the
                      industrial flooring requirements of our valued clients,
                      delivering unmatched service.
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      We specialize in effectively managing time-sensitive
                      projects and plant shutdowns,
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      Guaranteeing the on-time completion of projects through
                      the expertise of our skilled team.
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      Our support extends beyond project completion, ensuring
                      that our clients’ needs are always met to their
                      satisfaction.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="themesflat-spacer clearfix"
            data-desktop={80}
            data-mobile={60}
            data-smobile={60}
          />
        </div>
      </div>
    );
  }
}

export default SiteContentEsdFlooring;
