import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
class ServiceDetailAntiStaticEpoxyFlooring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          id: 1,
          text01:
            "The most popular form of epoxy resin that is used for epoxy coatings is Bisphenol A. Bisphenol A has a honey-liquid consistency and is an extremely versatile resin. Bisphenol A is available in many molecular weights. Their variety of molecular weights is what makes Bisphenol A especially versatile for the epoxy application. Bisphenol A is most widely used for flooring systems, in addition to solid coatings.  Bisphenol A epoxy resin is the most common type for concrete coatings because of its excellent adhesion, toughness, abrasion resistance, and chemical resistance. Due to its especially thick natural consistency, there will commonly be additives and diluents which are added into the Bisphenol-A epoxy formula to enhance workability and adhesion. These additives lessen crosslink density which lowers the chemical resistance.",
          image: "assets/img/ServicePage/Bisphenol-A.jpg",
        },
        {
          id: 2,
          text01:
            "Bisphenol F epoxy will typically be more expensive than Bisphenol A epoxy. This is because Bisphenol F epoxy is able to be used for a greater variety of applications. Bisphenol F resins have lower viscosity which means fewer additives and diluents are added which translates to higher chemical resistance. Bisphenol F is especially chemically resistant, and are more likely to be used as varnishes or lacquers, and for application regarding water pipes, adhesives, supermarket food packaging, plastics, dental sealants, and liners. Another advantage is that Bisphenol F has less tendency to crystallize at lower temperatures compared to Bisphenol A resins. Bisphenol F can be blended with Bisphenol A and other resins to reduce viscosity and increase resistance to crystallization.",
          image: "assets/img/ServicePage/Bisphenol-F.jpg",
        },
        {
          id: 3,
          text01:
            "Novolacs are made using excess phenol and modifying Bisphenol F Resins. The viscosity of Novolac resins is higher than Bisphenol F and its functionality is considerably greater than both Bisphenol A and Bisphenol F resins. The higher viscosity and greater functionality of Novolacs give them superior heat and chemical resistance than their Bisphenol A and Bisphenol F counterparts. The greater chemical resistant qualities is a result of the very tight crosslink density because of Novolac’s higher functionality. These properties also make Novolac resins more brittle than Both Bisphenol A and F resins so formulators need to address that with additives and hardener selection. The greater heat resistance is the result of the larger number of aromatic rings. As to be expected Novolac resins have a higher cost to go along with their higher performance.",
          image: "assets/img/ServicePage/Novolac.jpg",
        },
      ],
    };
  }

  render() {
    return (
      <div id="site-content" className="site-content clearfix">
        <Helmet>
          <title>Anti Static Flooring | Anti Static Epoxy Flooring</title>
          <meta
            name="description"
            content="Anti-Static Epoxy Flooring prevents electrostatic discharge (ESD) in sensitive environments like electronics labs and data centers. Durable, easy to maintain, and ideal for protecting equipment and personnel from static buildup."
          />
          <meta
            name="keywords"
            content="Anti-Static Epoxy Flooring, ESD Flooring, Electrical Discharge Protection, Industrial Flooring, Sensitive Environment Flooring, Static Control Flooring"
          />
          <meta name="author" content="Your Company Name" />
          <meta name="robots" content="index, follow" />
          <link
            rel="canonical"
            href="https://chemcoats.com/anti-static-epoxy-flooring"
          />
        </Helmet>
        <div id="inner-content" className="inner-content-wrap">
          <div
            className="themesflat-spacer clearfix"
            data-desktop={80}
            data-mobile={60}
            data-smobile={60}
          />
          <div className="themesflat-row equalize sm-equalize-auto clearfix">
            <div className="span_1_of_6 bg-f7f">
              <div
                className="themesflat-spacer clearfix"
                data-desktop={60}
                data-mobile={60}
                data-smobile={60}
              />
              <div
                className="themesflat-content-box clearfix"
                data-margin="0 10px 0 43px"
                data-mobilemargin="0 15px 0 15px"
              >
                <div className="themesflat-headings style-2 clearfix">
                  {/* <div className="sup-heading">SPECIAL SERVICES</div> */}
                  <h1 className="heading font-size-28 line-height-39">
                    ANTI-STATIC EPOXY FLOORING
                  </h1>
                  <div className="sep has-width w80 accent-bg margin-top-20 clearfix" />
                  <p className="sub-heading margin-top-33 line-height-24">
                    {/* Anti static flooring utilizes a material composition that
                    helps eliminate the risk of discharge (ESD) by creating a
                    controlled pathway for the safe release of electrical
                    charges. This makes it an essential element, in environments
                    that’re sensitive, to ESD. */}
                    Anti-static flooring makes use of a unique composition that
                    eliminates the hazards of a discharge. This implies the
                    preventive passage of electrical charges and is thus
                    necessary in sensitive ESD environments.
                  </p>
                </div>
              </div>
              <div
                className="themesflat-spacer clearfix"
                data-desktop={56}
                data-mobile={56}
                data-smobile={56}
              />
            </div>
            <div className="span_1_of_6 half-background style-2">
              <img
                src="assets/img/ServicePage/anti static.jpg"
                alt="Anti-static-flooring-image"
                title="Anti-static-flooring"
                className="ServiceIDpAGEiMAGE"
              />
            </div>
          </div>
          <div
            className="themesflat-spacer clearfix"
            data-desktop={39}
            data-mobile={39}
            data-smobile={39}
          />
          <div className="flat-content-wrap style-2 clearfix">
            {/* <h5 className="title">SERVICE CONSULTANT</h5> */}
            <p>
              {/* Anti-static flooring, also called electrostatic discharge (ESD)
              flooring, is designed to control and reduce static electricity
              within sensitive environments. It utilizes materials with high
              electrical conductivity or dissipative properties to redirect and
              disperse static charges, protecting electronic equipment and
              preventing sparks in environments prone to ignition. Anti-static
              flooring is crucial in industries like electronics manufacturing,   
              healthcare, and laboratories to maintain operational safety and
              equipment integrity. */}
              Anti-static flooring, Also known as electrostatic discharge (ESD)
              flooring, it is specially designed to control and reduce static
              electricity within sensitive environments. It includes many
              substances with high electrical conductivity or dissipative
              properties. The static charge created travels along a path across
              the flooring and is arrested somewhere else (into the floor),
              protecting the electronic equipment and thus preventing sparks in
              areas prone to ignition. In the industries such as electronics,
              pharmaceuticals, and laboratories, anti-static flooring is
              precious for the operational sustainability and integrity of the
              equipment.
            </p>
          </div>
          <div
            className="themesflat-spacer clearfix"
            data-desktop={37}
            data-mobile={35}
            data-smobile={35}
          />
          <div className="flat-content-wrap style-2 clearfix">
            <div className="item" style={{ width: "100%" }}>
              <div className="inner">
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={10}
                  data-mobile={10}
                  data-smobile={10}
                />
                <h5 className="title">
                  Why Anti-Static Epoxy Flooring for industries?
                </h5>
                {/* <p>
                  Epoxy flooring plays a vital role in the industry for several
                  compelling reasons:
                </p> */}
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={8}
                  data-mobile={8}
                  data-smobile={8}
                />
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          {" "}
                        </span>
                        {/* Anti-static flooring safeguards sensitive electronic
                        equipment from electrostatic discharge (ESD), which can
                        damage or disrupt their functionality. */}
                        It protects sensitive electronic equipment from ESD
                        during periods when it is damaged or becomes temporarily
                        inoperative.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        {" "}
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          {" "}
                          Safety :{" "}
                        </span>
                        {/* It reduces the risk of static shocks to personnel,
                        creating a safer environment, particularly in areas
                        where people handle volatile substances. */}
                        Reduces the risk of personal static shock, making the
                        workplace (particularly areas in which workers are
                        handling volatile materials) safer.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        {" "}
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          {" "}
                          Fire Hazard Prevention :{" "}
                        </span>
                        {/* In industries involving flammable materials, anti-static
                        flooring prevents sparks that could ignite fires or
                        explosions, ensuring workplace safety. */}
                        Anti-static flooring avoids sparks that by any means
                        could ignite fires or explosions, hence ensuring safety
                        at the workplace in industries handling flammable
                        materials.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        {" "}
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          {" "}
                          Product Quality :{" "}
                        </span>
                        {/* ESD can damage or alter the properties of products
                        during manufacturing. Anti-static flooring maintains
                        product quality by preventing such issues. */}
                        ESD could destroy or modify the qualities of products
                        during the manufacturing process. Anti-static flooring
                        protects product quality from being compromised.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        {" "}
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          {" "}
                          Cleanroom Compliance :{" "}
                        </span>
                        {/* Many cleanroom environments require anti-static flooring
                        to prevent static-related contamination in critical
                        manufacturing processes. */}
                        Most cleanroom environments require anti-static flooring
                        to curb static-related contamination in critical
                        manufacturing processes.
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      {" "}
                      <span style={{ fontWeight: "500", color: "#000" }}>
                        {" "}
                        Data Center Stability :{" "}
                      </span>
                      {/* Data centers rely on anti-static flooring to protect
                      servers and data storage equipment from ESD, ensuring
                      uninterrupted operations. */}
                      Data centre protection from ESD induced stray currents is
                      classic with anti-static flooring. Hence, it is
                      fundamental for the continuous operation of manufacturing
                      data storage and servers.
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      {" "}
                      <span style={{ fontWeight: "500", color: "#000" }}>
                        {" "}
                        Cost Savings :{" "}
                      </span>
                      {/* By preventing damage to equipment and products,
                      anti-static flooring reduces maintenance and replacement
                      costs. */}
                      Its contribution towards the availability and ultimate
                      functionality of production methods for all equipment
                      helps stabilise the maintenance and replacement costs
                      purposely to all leading company businesses.
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      {" "}
                      <span style={{ fontWeight: "500", color: "#000" }}>
                        {" "}
                        Compliance with Regulations :{" "}
                      </span>
                      {/* Various industries have strict regulations requiring the
                      use of anti-static flooring to maintain safety and quality
                      standards. */}
                      There are diversified industries that exist by legislative
                      law concerning the anti-static flooring requirement to
                      uphold the notions of safety and quality.
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      {" "}
                      <span style={{ fontWeight: "500", color: "#000" }}>
                        {" "}
                        Employee Comfort :{" "}
                      </span>
                      {/* Reducing static shocks enhances employee comfort and
                      productivity, leading to a better work environment. */}
                      The less bothered an employee is when it comes to damping
                      static shocks, the more comfortable, productive, and
                      encouraged he'll be
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      {" "}
                      <span style={{ fontWeight: "500", color: "#000" }}>
                        {" "}
                        Longevity of Flooring :{" "}
                      </span>
                      Anti-static flooring is durable, making it a long-lasting
                      investment that maintains its ESD properties over time,
                      ensuring ongoing protection.
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="item">
              <div
                className="themesflat-spacer clearfix"
                data-desktop={0}
                data-mobile={0}
                data-smobile={35}
              />
              <div
                className="themesflat-content-box clearfix"
                data-margin="0 0 0 11px"
                data-mobilemargin="0 0 0 0"
              >
                <div className="inner pd35 bg-ffc">
                  <h5 className="title text-white line-height-24 padding-left-7">
                    THIS AWESOME SERVICE BRINGS FULL INTEREST INFORMATION FOR
                    YOUR PURPOSE
                  </h5>
                  <div
                    className="themesflat-spacer clearfix"
                    data-desktop={16}
                    data-mobile={16}
                    data-smobile={16}
                  />
                  <div className="button-wrap has-icon icon-right size-14">
                    <Link
                      to="#"
                      className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26"
                    >
                      <span>
                        Download .PDF{" "}
                        <span className="icon">
                          <i className="fa fa-file-pdf-o" />
                        </span>
                      </span>
                    </Link>
                  </div>
                  <div
                    className="themesflat-spacer clearfix"
                    data-desktop={6}
                    data-mobile={6}
                    data-smobile={6}
                  />
                  <div className="button-wrap has-icon icon-right size-14">
                    <Link
                      to="#"
                      className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26 "
                    >
                      <span>
                        Download .DOC{" "}
                        <span className="icon">
                          <i className="fa fa-file-word-o" />
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div
            className="themesflat-spacer clearfix"
            data-desktop={37}
            data-mobile={35}
            data-smobile={35}
          />
          <div className="flat-content-wrap style-2 clearfix">
            <h5 className="title no-letter-spacing">
              TYPES OF EPOXY COMPOSITON
            </h5>
            <div
              className="themesflat-spacer clearfix"
              data-desktop={27}
              data-mobile={27}
              data-smobile={27}
            />
            <div className="themesflat-tabs style-2 title-w170 clearfix">
              <ul className="tab-title clearfix ">
                <li className="item-title active">
                  <span className="inner">Bisphenol A</span>
                </li>
                <li className="item-title">
                  <span className="inner">Bisphenol F</span>
                </li>
                <li className="item-title">
                  <span className="inner">Novolac</span>
                </li>
              </ul>
              <div className="tab-content-wrap clearfix">
                {this.state.tabs.map((data) => (
                  <div className="tab-content" key={data.id}>
                    <div className="item-content">
                      <p>{data.text01}</p>
                      <p>{data.text02}</p>

                      <div className="servicePageImagemap">
                        <img
                          src={data.image}
                          alt=" Anti-static-flooring-image"
                          className="serviceImaGEMAPDIV"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}

          <div
            className="themesflat-spacer clearfix"
            data-desktop={37}
            data-mobile={35}
            data-smobile={35}
          />
          <div className="flat-content-wrap style-2 clearfix">
            <div className="item" style={{ width: "100%" }}>
              <div className="inner">
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={10}
                  data-mobile={10}
                  data-smobile={10}
                />
                <h5 className="title">
                  Why Consider ChemCoats For Anti-Static Epoxy Flooring?
                </h5>

                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={8}
                  data-mobile={8}
                  data-smobile={8}
                />
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="text">
                      With, over 15 years of experience in providing Industrial
                      flooring services across India we pride ourselves on
                      offering expert guidance and paying attention to every
                      detail. Our top priority is ensuring 100% transparency and
                      customer satisfaction. We are committed to delivering a
                      flooring system that is designed for lasting performance
                      thanks, to our exceptional craftsmanship. At Chemcoats our
                      core vision revolves around meeting all the flooring
                      requirements of our valued clients and providing service.
                      We specialize in managing time projects and plant
                      shutdowns guaranteeing the timely completion of projects
                      through the expertise of our skilled team. Furthermore our
                      support extends beyond project completion as we
                      continuously strive to meet our clients needs and ensure
                      their satisfaction.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="themesflat-spacer clearfix"
            data-desktop={37}
            data-mobile={35}
            data-smobile={35}
          />
          <div className="flat-content-wrap style-2 clearfix">
            <div className="item" style={{ width: "100%" }}>
              <div className="inner">
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={10}
                  data-mobile={10}
                  data-smobile={10}
                />
                <h5 className="title">
                  Advantages of chemcoats Anti-Static Epoxy flooring
                </h5>

                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={8}
                  data-mobile={8}
                  data-smobile={8}
                />
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        {" "}
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          {" "}
                          Electrical Conductivity :{" "}
                        </span>
                        Anti-static epoxy flooring has a controlled level of
                        electrical conductivity, typically in the range of 1.0 x
                        10^4 to 1.0 x 10^6 ohms, which allows it to safely
                        dissipate static charges.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        {" "}
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          {" "}
                          Static Dissipation :{" "}
                        </span>
                        {/* It effectively dissipates static charges to prevent the
                        build-up of electrostatic potential on the surface,
                        reducing the risk of ESD damage to sensitive electronic
                        components. */}
                        It is efficient enough to dissipate static charges and
                        does not allow the electrostatic potential on the
                        surface to build up, thus lessening the possibility of
                        ESD damage to sensitive electronic components.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        {" "}
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          {" "}
                          Durable Surface :{" "}
                        </span>
                        {/* Anti-static epoxy flooring is highly durable and can
                        withstand heavy foot traffic, equipment, and chemical
                        exposure, making it suitable for industrial and
                        commercial settings. */}
                        Anti-static epoxy flooring can withstand heavy foot
                        traffic, equipment, and chemical exposure and is strong
                        in this sense, that it can be used for industrial and
                        commercial purposes.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        {" "}
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          {" "}
                          Chemical Resistance :{" "}
                        </span>
                        {/* It is resistant to a wide range of chemicals, including
                        acids, alkalis, solvents, and oils, ensuring that it
                        remains intact and functional in challenging
                        environments. */}
                        It has good resistance against chemicals such as acids,
                        alkalis, solvents, and oils and is expected to be robust
                        and functional even in the toughest of environments.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        {" "}
                        <span style={{ fontWeight: "500", color: "#000" }}>
                          {" "}
                          Seamless Installation :{" "}
                        </span>
                        {/* Anti-static epoxy flooring is applied as a seamless,
                        monolithic surface, eliminating seams and joints where
                        static charge can accumulate. */}
                        Anti-static epoxy flooring is applied as a seamless,
                        monolithic surface eliminating seams and joints where
                        static charge may build up.
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      {" "}
                      <span style={{ fontWeight: "500", color: "#000" }}>
                        {" "}
                        Low Maintenance :{" "}
                      </span>
                      {/* This type of flooring is easy to clean and maintain,
                      reducing long-term operational costs. */}
                      It is easy to clean and maintain this type of flooring,
                      and its long-term operational costs will be low.
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      {" "}
                      <span style={{ fontWeight: "500", color: "#000" }}>
                        {" "}
                        Hygienic Properties :{" "}
                      </span>
                      {/* Anti-static epoxy flooring can be formulated with
                      antimicrobial properties, making it suitable for use in
                      healthcare facilities and laboratories where cleanliness
                      is crucial. */}
                      Epoxy floors with anti-static properties can also be
                      developed by adding antimicrobial properties to protect
                      against microbes for healthcare and lab applications where
                      hygienic values are high.
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      {" "}
                      <span style={{ fontWeight: "500", color: "#000" }}>
                        {" "}
                        Impact Resistance :{" "}
                      </span>
                      {/* It offers good impact resistance, protecting the substrate
                      and ensuring the longevity of the flooring system. */}
                      It offers excellent impact resistance protection to the
                      underlying substrate, thereby sustaining the life cycle of
                      the floor system.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Image Slider */}
          <div
            className="themesflat-spacer clearfix"
            data-desktop={37}
            data-mobile={35}
            data-smobile={35}
          />
          <div className="row-services">
            <div className="container">
              <h5 className="title">Anti-Static Epoxy Flooring Images :</h5>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="themesflat-carousel-box data-effect clearfix"
                    data-gap={30}
                    data-column={3}
                    data-column2={2}
                    data-column3={1}
                    data-auto="true"
                  >
                    <div className="owl-carousel owl-theme">
                      <div className="themesflat-image-box style-2 clearfix">
                        <div className="image-box-item">
                          <div className="inner">
                            <div className="thumb data-effect-item">
                              <img
                                src="assets\img\ServicePage\epoxy-flooring3.jpg"
                                alt="Anti-static-flooring-image"
                                title="Anti-static-flooring"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="themesflat-image-box style-2 clearfix">
                        <div className="image-box-item">
                          <div className="inner">
                            <div className="thumb data-effect-item">
                              <img
                                src="assets\img\ServicePage\PU1.jpg"
                                alt="Anti-static-flooring-image"
                                title="Anti-static-flooring"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="themesflat-image-box style-2 clearfix">
                        <div className="image-box-item">
                          <div className="inner">
                            <div className="thumb data-effect-item">
                              <img
                                src="assets\img\ServicePage\Di-electric-flooring.jpg"
                                alt="Anti-static-flooring-image"
                                title="Anti-static-flooring"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="themesflat-image-box style-2 clearfix">
                        <div className="image-box-item">
                          <div className="inner">
                            <div className="thumb data-effect-item">
                              <img
                                src="assets\img\ServicePage\IMG-20220628-WA0007.jpg"
                                alt="Anti-static-flooring-image"
                                title="Anti-static-flooring"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="themesflat-image-box style-2 clearfix">
                        <div className="image-box-item">
                          <div className="inner">
                            <div className="thumb data-effect-item">
                              <img
                                src="assets\img\ServicePage\esd-flooring-5.jpg"
                                alt="Anti-static-flooring-image"
                                title="Anti-static-flooring"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="themesflat-spacer clearfix"
                      data-desktop={45}
                      data-mobile={60}
                      data-smobile={60}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Image Slider */}

          <div className="flat-content-wrap style-2 clearfix">
            <div className="item" style={{ width: "100%" }}>
              <div className="inner">
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={10}
                  data-mobile={10}
                  data-smobile={10}
                />
                <h5 className="title">
                  Why Choose ChemCoats For Anti-Static Epoxy Flooring?
                </h5>

                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={8}
                  data-mobile={8}
                  data-smobile={8}
                />
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        15 years of Industrial Epoxy flooring services in India
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        Expert guidance and meticulous attention to every
                        detail.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        100% transparency and customer satisfaction
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        Exceptional craftsmanship and a dependable flooring
                        system designed to deliver long-lasting performance
                      </span>
                    </span>
                  </div>
                </div>
                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                  <div className="inner">
                    <span className="item">
                      <span className="icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="text">
                        High-quality installation services with modern equipment
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      The core vision of Chemcoats is to fulfill all the
                      industrial flooring requirements of our esteemed clients
                      and deliver unparalleled service
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      We excel in managing time-sensitive projects and plant
                      shutdowns
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      Guaranteeing on-time completion of projects by our skilled
                      team
                    </span>
                  </span>
                </div>
              </div>
              <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                <div className="inner">
                  <span className="item">
                    <span className="icon">
                      <i className="fa fa-check-circle" />
                    </span>
                    <span className="text">
                      We provide 100% support even after the entire project is
                      finished, ensuring clients needs are always met.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="themesflat-spacer clearfix"
            data-desktop={80}
            data-mobile={60}
            data-smobile={60}
          />
        </div>
      </div>
    );
  }
}

export default ServiceDetailAntiStaticEpoxyFlooring;
